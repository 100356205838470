"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageScene = void 0;
const constants_1 = require("../constants");
class LandingPageScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'LandingPageScene',
        });
    }
    create() {
        window.addEventListener('resize', this.resizeGame.bind(this));
        this.addBackgroundImage();
        this.header();
        this.createUserForm();
        this.events.on('shutdown', () => {
            const gap_dash_form = document.getElementById('landing-page-form');
            if (gap_dash_form)
                gap_dash_form.remove();
            const header = document.getElementById('landing-page-header');
            if (header)
                header.remove();
            const bg = document.getElementById('landing-bg');
            if (bg)
                bg.remove();
        });
    }
    addBackgroundImage() {
        const divWrapper = document.createElement('div');
        divWrapper.id = 'landing-bg';
        this.add.dom(0, 0).createFromHTML(divWrapper.outerHTML);
        document.body.appendChild(divWrapper);
    }
    header() {
        const header = document.createElement('header');
        header.id = 'landing-page-header';
        const logo = document.createElement('img');
        logo.src = './assets/images/stores/logo.webp';
        logo.className = 'landing-page-logo';
        header.appendChild(logo);
        this.add.dom(0, 0).createFromHTML(header.outerHTML);
        document.body.appendChild(header);
    }
    createUserForm() {
        let gapDashForm = document.createElement('div');
        gapDashForm.id = 'landing-page-form';
        gapDashForm.style.position = 'absolute';
        const divPlayWrapper = document.createElement('div');
        divPlayWrapper.className = 'play-wrapper';
        const divTwinbarLeft = document.createElement('div');
        divTwinbarLeft.className = 'twin-bar twin-bar-left';
        const divTwinbarRight = document.createElement('div');
        divTwinbarRight.className = 'twin-bar twin-bar-right';
        let playButton = document.createElement('button');
        playButton.id = 'play-game';
        divPlayWrapper.appendChild(divTwinbarLeft);
        divPlayWrapper.appendChild(playButton);
        divPlayWrapper.appendChild(divTwinbarRight);
        this.chPlayButton = document.createElement('button');
        this.chPlayButton.type = 'button';
        this.chPlayButton.id = 'ch_play_button';
        this.chPlayButton.className = 'landing-page-button';
        this.chPlayButton.innerHTML = '';
        this.appStoreButton = document.createElement('button');
        this.appStoreButton.type = 'button';
        this.appStoreButton.id = 'app_store_button';
        this.appStoreButton.className = 'landing-page-button';
        this.appStoreButton.innerHTML = '';
        let divStoreWrapper = document.createElement('div');
        divStoreWrapper.className = 'store-wrapper';
        divStoreWrapper.appendChild(this.chPlayButton);
        divStoreWrapper.appendChild(this.appStoreButton);
        let comingSoon = document.createElement('img');
        comingSoon.src = './assets/images/stores/app-comingsoon.webp';
        comingSoon.className = 'coming-soon-title';
        gapDashForm.appendChild(divPlayWrapper);
        gapDashForm.appendChild(comingSoon);
        gapDashForm.appendChild(divStoreWrapper);
        this.add.dom(0, 0).createFromHTML(gapDashForm.outerHTML);
        document.body.appendChild(gapDashForm);
        this.chPlayButton.addEventListener('click', () => {
            // alert('App is coming soon');
        });
        this.appStoreButton.addEventListener('click', () => {
            // alert('App is coming soon');
        });
        playButton.addEventListener('click', () => {
            if (this.resizeGame() > 1000) {
                this.scene.start(constants_1.SceneCons.MainMenuScene);
            }
            else {
                this.scene.start(constants_1.SceneCons.LandingPageDisableScene);
            }
        });
    }
    resizeGame() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        this.scale.resize(width, height);
        return width;
    }
}
exports.LandingPageScene = LandingPageScene;
