"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postScore = exports.firebaseLogEvent = exports.logLevelCompletion = exports.logLevelStart = void 0;
const app_1 = require("firebase/app");
const analytics_1 = require("firebase/analytics");
const envConfig_1 = require("./envConfig");
const firebaseConfig = {
    apiKey: envConfig_1.envConfig.firebase.apiKey,
    authDomain: envConfig_1.envConfig.firebase.authDomain,
    projectId: envConfig_1.envConfig.firebase.projectId,
    storageBucket: envConfig_1.envConfig.firebase.storageBucket,
    messagingSenderId: envConfig_1.envConfig.firebase.messagingSenderId,
    appId: envConfig_1.envConfig.firebase.appId,
    measurementId: envConfig_1.envConfig.firebase.measurementId,
};
const app = (0, app_1.initializeApp)(firebaseConfig);
const analytics = (0, analytics_1.getAnalytics)(app);
(0, analytics_1.setAnalyticsCollectionEnabled)(analytics, envConfig_1.envConfig.shouldMakeAnalyticsRequests);
function firebaseLogEvent(eventName, eventParams) {
    (0, analytics_1.logEvent)(analytics, eventName, eventParams);
}
exports.firebaseLogEvent = firebaseLogEvent;
function logLevelStart(level, playerName, email) {
    firebaseLogEvent('level_start', {
        level,
        playerName,
        email,
    });
}
exports.logLevelStart = logLevelStart;
function logLevelCompletion(level, playerName, email) {
    firebaseLogEvent('level_end', {
        level,
        playerName,
        email,
    });
}
exports.logLevelCompletion = logLevelCompletion;
function postScore(score, playerName, email) {
    firebaseLogEvent('post_score', {
        score,
        playerName,
        email,
    });
}
exports.postScore = postScore;
