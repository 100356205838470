"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = void 0;
require("phaser");
const config_1 = require("./config");
const game_registry_1 = require("./utils/registrys/game-registry");
const envConfig_1 = require("./utils/envConfig");
class Game extends Phaser.Game {
    constructor(config) {
        super(config);
    }
}
exports.Game = Game;
console.log(envConfig_1.envConfig.envName);
let game;
const game_scene = [''];
function pauseAllScenes(game) {
    try {
        game_scene.length = 0;
        game.scene.getScenes().forEach((scene) => {
            game.scene.pause(scene.scene.key);
            game_scene.push(scene.scene.key);
        });
    }
    catch (ex) {
        console.log(ex);
    }
}
function resumeAllScenes(game) {
    try {
        game_scene.forEach(scene => {
            game.scene.resume(scene);
        });
    }
    catch (ex) {
        console.log(ex);
    }
}
function resize(game) {
    const canvas = game.canvas;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const wratio = width / height;
    const ratio = canvas.width / canvas.height;
    // console.log({ wratio, ratio, width, height });
    if (wratio < ratio) {
        canvas.style.width = `${width}px`;
        canvas.style.height = `${width / ratio}px`;
    }
    else {
        canvas.style.width = `${height * ratio}px`;
        canvas.style.height = `${height}px`;
    }
}
window.addEventListener('load', () => {
    if (!game)
        return;
    (0, game_registry_1.setGameInstance)(game);
    document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
            pauseAllScenes(game);
        }
        else {
            resumeAllScenes(game);
        }
    });
});
window.addEventListener('resize', () => {
    if (!game)
        return;
    resize(game);
});
game = new Game(config_1.GameConfig);
