"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomNumber = exports.getSpeed = exports.generateLevels = exports.getRandomXPositions = exports.convertToKm = exports.getCenterPosition = exports.calculateTimeAccordingToVelocity = void 0;
const constants_1 = require("../constants");
const Phaser = require("phaser");
const game_registry_1 = require("./registrys/game-registry");
const calculateTimeAccordingToVelocity = (timeDesire = 2000, velocityDesire = 300) => {
    return (timeDesire * velocityDesire) / (constants_1.CONST.VELOCITY * constants_1.CONST.SPEED);
};
exports.calculateTimeAccordingToVelocity = calculateTimeAccordingToVelocity;
function getCenterPosition(scene, objWidth, objHeight) {
    const screenWidth = scene.cameras.main.width;
    const screenHeight = scene.cameras.main.height;
    return {
        x: (screenWidth - objWidth) / 2,
        y: (screenHeight - objHeight) / 2,
    };
}
exports.getCenterPosition = getCenterPosition;
const convertToKm = (distance) => {
    const km = distance / 1000;
    return parseFloat(km.toFixed(2));
};
exports.convertToKm = convertToKm;
/**
 * Generates multiple random x positions between point A and B, ensuring each is at least `minDistance` apart.
 * @param {number} a - The starting x position (point A).
 * @param {number} b - The ending x position (point B).
 * @param {number} n - The number of random points to generate.
 * @param {number} minDistance - The minimum distance required between any two points.
 * @returns {Array} An array containing the random x positions.
 */
function getRandomXPositions(a, b, n, minDistance) {
    let randomPositions = [];
    let attempts = 0;
    while (randomPositions.length < n) {
        if (attempts++ > 10000) {
            // Prevent infinite loops
            console.error('Too many attempts to find positions.');
            break;
        }
        let newPoint = Phaser.Math.Between(a, b);
        let tooClose = randomPositions.some(pos => Math.abs(pos - newPoint) < minDistance);
        if (!tooClose) {
            randomPositions.push(newPoint);
        }
    }
    return randomPositions;
}
exports.getRandomXPositions = getRandomXPositions;
function generateLevels(scoreCutScene) {
    const desiredLength = Math.floor(scoreCutScene / 30 / 10);
    const level1 = [
        0, 1, 2, 5, 6, 10, 11, 12, 13, 16, 17, 21, 22, 25, 26, 27, 31, 32, 35, 36,
        37, 40, 41, 45, 46, 50, 51, 55, 56, 60,
    ];
    const level2 = [
        0, 2, 3, 6, 9, 12, 13, 14, 17, 20, 23, 26, 27, 32, 33, 34, 36, 37, 39, 42,
        43, 46, 47, 50, 52, 55, 58, 61, 64, 67,
    ];
    const beginLoop = level1.length;
    for (let i = beginLoop; level1.length < desiredLength || level2.length < desiredLength; i++) {
        if (level1.length < desiredLength && (i % 5 === 0 || i % 7 === 0)) {
            level1.push(i);
        }
        if (level2.length < desiredLength && (i % 3 === 0 || i % 4 === 0)) {
            level2.push(i);
        }
    }
    return { level1, level2 };
}
exports.generateLevels = generateLevels;
const getSpeed = () => {
    const times = game_registry_1.default.getTimesThePlayerSwitchesCutScene();
    const originalSpeed = constants_1.CONST.SPEED * constants_1.CONST.VELOCITY;
    const valuePercent = (constants_1.CONST.SPEED_PERCENT_INCREASE / 100) * originalSpeed;
    const currentSpeed = originalSpeed + valuePercent * times;
    return currentSpeed;
};
exports.getSpeed = getSpeed;
const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
exports.randomNumber = randomNumber;
