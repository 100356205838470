"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Level = void 0;
const utils_1 = require("../utils");
const registrys_1 = require("../utils/registrys");
class Level extends Phaser.Scene {
    constructor() {
        super({ key: 'Level' });
        this._scoreRegistry = new registrys_1.ScoreRegistry();
    }
    create() {
        const marginRight = this.sys.canvas.width - 170;
        this.levelSprite = this.add.sprite(marginRight, 20, 'level');
        this.levelSprite.setOrigin(0, 0);
        this.levelSprite.setScale(1.2);
        this.distanceText = this.add.bitmapText(marginRight, 45, 'font', `DISTANCE: ${(0, utils_1.convertToKm)(this._scoreRegistry.getScore())}KM`, 12);
        const highestScore = localStorage.getItem('highestScore');
        this.highScoreText = this.add.bitmapText(marginRight, 62, 'font', highestScore ? `HIGHEST: ${(0, utils_1.convertToKm)(Number(highestScore))}KM` : '', 12);
        this.time.addEvent({
            // delay: calculateTimeAccordingToVelocity(800),
            delay: 1000,
            callback: this.increaseScore,
            callbackScope: this,
            loop: true,
        });
    }
    increaseScore() {
        this.distanceText.setText(`DISTANCE: ${(0, utils_1.convertToKm)(this._scoreRegistry.getScore())}KM`);
    }
}
exports.Level = Level;
