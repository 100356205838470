"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesertBackBox = exports.DesertBackScene = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
class DesertBackScene extends Phaser.Scene {
    constructor() {
        super({ key: 'DesertBack' });
    }
    init(data) {
        this._isLoop = data.isLoop !== undefined ? data.isLoop : true;
    }
    create() {
        this.desert = this.add.group({});
        this.createInitialDesertBack();
        // if (this._isLoop) {
        //   this.time.addEvent({
        //     delay: CONST.VELOCITY * 10 * CONST.SPEED,
        //     callback: this.addNewRowOfDesertBacks,
        //     callbackScope: this,
        //     loop: true,
        //   });
        // }
    }
    update() { }
    createInitialDesertBack() {
        const minDistance = 120;
        for (let i = 0; i < 1000; i++) {
            const xPositions = (0, utils_1.getRandomXPositions)(i * constants_1.CONST.WORLD_FRAME.W + minDistance, (i + 1) * constants_1.CONST.WORLD_FRAME.W, 2, minDistance);
            xPositions.forEach(x => {
                this.addNewRowOfDesertBacks(x);
            });
        }
    }
    addNewRowOfDesertBacks(x = 1300) {
        this.desert.add(new DesertBackBox({
            scene: this,
            x: x,
            y: this.sys.canvas.height - 380,
            texture: `desert-back`,
        }, this._isLoop));
    }
}
exports.DesertBackScene = DesertBackScene;
class DesertBackBox extends Phaser.GameObjects.Image {
    constructor(aParams, isVelocity = true) {
        super(aParams.scene, aParams.x, aParams.y, aParams.texture);
        // image
        this.setOrigin(0, 0);
        this.setDepth(1);
        // physics
        this.scene.physics.world.enable(this);
        this.body.allowGravity = false;
        if (isVelocity) {
            // It will move to the left 15% of the ground speed.
            this.body.setVelocityX(-(constants_1.CONST.VELOCITY * 0.15 * constants_1.CONST.SPEED));
        }
        this.scene.add.existing(this);
    }
}
exports.DesertBackBox = DesertBackBox;
