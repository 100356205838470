"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pin = void 0;
const constants_1 = require("../constants");
class Pin extends Phaser.Physics.Arcade.Sprite {
    constructor(aParams) {
        super(aParams.scene, aParams.x, aParams.y, null);
        this.setScale(2);
        this.setOrigin(0, 0);
        // physics
        this.scene.physics.world.enable(this);
        this.body.allowGravity = false;
        this.body.setVelocityX(-constants_1.CONST.VELOCITY * constants_1.CONST.SPEED);
        this.scene.add.existing(this);
        // Create animations
        this.scene.anims.create({
            key: 'pin',
            frames: this.scene.anims.generateFrameNumbers('pin', {
                start: 0,
                end: -1,
            }),
            frameRate: 12,
            repeat: -1,
        });
        this.anims.play('pin', true);
    }
}
exports.Pin = Pin;
