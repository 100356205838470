"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plane = void 0;
const constants_1 = require("../../constants");
class Plane extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y) {
        super(scene, x, y, 'plane-taking-off');
        this.scene = scene;
        scene.add.existing(this);
        scene.physics.add.existing(this);
        scene.physics.world.enable(this);
        this.setScale(1.3);
        // physics
        this.body.allowGravity = false;
        this.body.setVelocityX(constants_1.CONST.VELOCITY * 0.5);
        this.body.setVelocityY(constants_1.CONST.VELOCITY * 0.5);
        // Create animations
        scene.anims.create({
            key: Plane.TEXT_PLANE_TAKING_OFF,
            frames: scene.anims.generateFrameNumbers(Plane.TEXT_PLANE_TAKING_OFF, {}),
            frameRate: 5,
            repeat: 0,
        });
        scene.anims.create({
            key: Plane.TEXT_PLANE_LANDS,
            frames: scene.anims.generateFrameNumbers(Plane.TEXT_PLANE_LANDS, {}),
            frameRate: 5,
            repeat: 0,
        });
        scene.anims.create({
            key: Plane.TEXT_PLANE_NORMAL,
            frames: scene.anims.generateFrameNumbers(Plane.TEXT_PLANE_NORMAL, {
                start: 0,
                end: 3,
            }),
            frameRate: 5,
            repeat: -1,
        });
        this.anims.play(Plane.TEXT_PLANE_NORMAL, false);
    }
    startPlaneLands() {
        this.anims.play(Plane.TEXT_PLANE_LANDS, false);
    }
    planeTakeOff() {
        this.anims.play(Plane.TEXT_PLANE_TAKING_OFF, false);
        setTimeout(() => {
            this.body.setVelocityY(-constants_1.CONST.VELOCITY * 0.4);
            this.body.setVelocityX(constants_1.CONST.VELOCITY * 1);
            this.anims.play(Plane.TEXT_PLANE_NORMAL, false);
        }, 1000);
    }
}
exports.Plane = Plane;
Plane.TEXT_PLANE_LANDS = 'plane-lands';
Plane.TEXT_PLANE_NORMAL = 'plane-normal';
Plane.TEXT_PLANE_TAKING_OFF = 'plane-taking-off';
