"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CongratulationScene = void 0;
const utils_1 = require("../utils");
const registrys_1 = require("../utils/registrys");
const score_local_storage_1 = require("../utils/score-local-storage");
class CongratulationScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'CongratulationScene',
        });
        this.newScoreInfo = (score) => {
            const newSoreDiv = document.createElement('div');
            newSoreDiv.className = 'new-score-container';
            newSoreDiv.innerHTML = `NEW SCORE: ${score} POINT(S)`;
            return newSoreDiv;
        };
        this.congratulationInfo = () => {
            const congDiv = document.createElement('div');
            congDiv.className = 'sub-card';
            const title = document.createElement('h1');
            title.innerHTML = 'YOU HAVE FINISHED WITH';
            congDiv.appendChild(title);
            return congDiv;
        };
        this.GameInfo = () => {
            const congDiv = document.createElement('div');
            congDiv.className = 'game-info';
            const distanceDiv = document.createElement('div');
            const batteryDiv = document.createElement('div');
            const rankDiv = document.createElement('div');
            rankDiv.className = 'hover';
            rankDiv.id = 'rankDiv';
            const imgDistance = document.createElement('img');
            imgDistance.src = 'assets/images/icons/distance.webp';
            const spanDistance = document.createElement('span');
            spanDistance.innerHTML = `${(0, utils_1.convertToKm)(this._scoreRegistry.getScore() || 0)} <br/> DISTANCE`;
            distanceDiv.appendChild(imgDistance);
            distanceDiv.appendChild(spanDistance);
            const imgBattery = document.createElement('img');
            imgBattery.src = 'assets/images/icons/pin.webp';
            const spanPin = document.createElement('span');
            spanPin.innerHTML = `${parseInt(this.registry.values.batteryConsumptionCount || 0)} <br/> BATTERY`;
            batteryDiv.appendChild(imgBattery);
            batteryDiv.appendChild(spanPin);
            const imgJump = document.createElement('img');
            imgJump.src = 'assets/images/icons/rank.webp';
            const spanJum = document.createElement('span');
            spanJum.innerHTML = `${this.scoreStorage.getRank(Number(this.registry.values.userActualDistance || 0))} <br/> YOUR RANK`;
            rankDiv.appendChild(imgJump);
            rankDiv.appendChild(spanJum);
            congDiv.appendChild(distanceDiv);
            congDiv.appendChild(batteryDiv);
            congDiv.appendChild(rankDiv);
            rankDiv.onclick = () => {
                this.scene.start('LeaderBoardScene');
            };
            return congDiv;
        };
        this.ActionButton = () => {
            const actionDiv = document.createElement('div');
            actionDiv.className = 'action-button-container';
            const playAgainButton = document.createElement('button');
            playAgainButton.id = 'playAgainButton';
            playAgainButton.innerHTML = 'PLAY AGAIN';
            playAgainButton.className = 'action-button gap_button';
            playAgainButton.addEventListener('mouseover', () => {
                playAgainButton.classList.add('gap-button-hover');
            });
            playAgainButton.addEventListener('mouseout', () => {
                playAgainButton.classList.remove('gap-button-hover');
            });
            playAgainButton.onclick = () => {
                this._scoreRegistry.resetScore();
                this.registry.set('preRoundScore', 0);
                this.registry.set('batteryConsumptionCount', 0);
                this.registry.set('numberOfTimesThePlayerSwitchesCutScene', 0);
                this._audioManager.playBackgroundMusic();
                this.scene.start('GameScene');
            };
            actionDiv.appendChild(playAgainButton);
            return actionDiv;
        };
        this.scoreStorage = new score_local_storage_1.default();
        this._scoreRegistry = new registrys_1.ScoreRegistry();
    }
    preload() {
        this.load.image('playAgainButton', 'assets/images/play-again.webp');
    }
    create() {
        this._audioManager = this.game.audioManager;
        const { width, height } = this.sys.game.canvas;
        const backgroundImage = this.add.image(0, 0, 'landingBackground');
        backgroundImage.setOrigin(0, 0);
        backgroundImage.displayWidth = width;
        backgroundImage.displayHeight = height;
        this.createGameOverInfo();
        this.events.on('shutdown', () => {
            const form = document.getElementById('gameOverCard');
            if (form) {
                form.remove();
            }
        });
    }
    createGameOverInfo() {
        const highestScore = this.scoreStorage.getHighScore();
        const overCard = document.createElement('div');
        overCard.id = 'gameOverCard';
        overCard.className = 'card';
        const title = document.createElement('h1');
        title.innerHTML = '!! GAME OVER !!';
        title.className = `game-over-title congratulation`;
        overCard.appendChild(title);
        overCard.appendChild(this.congratulationInfo());
        const currentScore = this._scoreRegistry.getScore();
        if (highestScore < currentScore) {
            this.scoreStorage.saveScore(currentScore);
            overCard.appendChild(this.newScoreInfo(currentScore));
        }
        overCard.appendChild(this.GameInfo());
        overCard.appendChild(this.ActionButton());
        this.add.dom(0, 0).createFromHTML(overCard.outerHTML);
        document.body.appendChild(overCard);
    }
}
exports.CongratulationScene = CongratulationScene;
