"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _1 = require(".");
class ScoreStorage {
    constructor() {
        this._score_prefix = 'GAP_DASH_HIGH_SCORE';
        this._player_name = 'GAP_DASH_PLAYER_NAME';
        this._player_email = 'GAP_DASH_PLAYER_EMAIL';
        this.saveScore = (score) => {
            try {
                const scoreStored = this.getHighScore();
                const highScore = Math.max(scoreStored, Number(score));
                localStorage.setItem(this._score_prefix, `${highScore}`);
            }
            catch (ex) { }
        };
        this.getHighScore = () => {
            try {
                const score = localStorage.getItem(this._score_prefix);
                return score ? Number(score) : 0;
            }
            catch (ex) {
                return 0;
            }
        };
        this.getRank = (distance) => {
            const currentScore = (0, _1.convertToKm)(this.getHighScore());
            const scoreDistance = (0, _1.convertToKm)(distance);
            const score = Math.max(currentScore, scoreDistance);
            if (score < 10)
                return 'Beginner';
            if (score < 20)
                return 'Bronze';
            if (score < 30)
                return 'Silver';
            if (score < 40)
                return 'Diamond';
            if (score < 50)
                return 'Platinum';
            return 'Master';
        };
        this.getPlayerName = () => {
            try {
                return localStorage.getItem(this._player_name) || '';
            }
            catch (ex) {
                return '';
            }
        };
        this.getPlayerEmail = () => {
            try {
                return localStorage.getItem(this._player_email) || '';
            }
            catch (ex) {
                return '';
            }
        };
    }
    savePlayerNameAndEmail(name, email) {
        try {
            localStorage.removeItem(this._player_name);
            localStorage.removeItem(this._player_email);
            if (Boolean(name)) {
                localStorage.setItem(this._player_name, name);
            }
            if (Boolean(email)) {
                localStorage.setItem(this._player_email, email);
            }
        }
        catch (ex) { }
    }
}
exports.default = ScoreStorage;
