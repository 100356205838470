"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IBaseRegistry = void 0;
const game_registry_1 = require("./game-registry");
class IBaseRegistry {
    constructor(keyStr) {
        this._STR_KEY = keyStr;
    }
    setVal(val) {
        (0, game_registry_1.writeRegistry)(this._STR_KEY, val);
    }
    getVal() {
        return (0, game_registry_1.readRegistry)(this._STR_KEY);
    }
    resetVal() {
        (0, game_registry_1.writeRegistry)(this._STR_KEY, null);
    }
}
exports.IBaseRegistry = IBaseRegistry;
