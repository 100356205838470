"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkyScene = void 0;
const cloud_1 = require("./cloud");
class SkyScene extends Phaser.Scene {
    constructor() {
        super({ key: 'Sky' });
    }
    create() {
        this.clouds = this.add.group({});
        this.createInitialClouds();
        this.time.addEvent({
            delay: 3000,
            callback: this.addNewRowOfClouds,
            callbackScope: this,
            loop: true,
        });
    }
    update() { }
    createInitialClouds() {
        for (let i = 0; i < 15; i++) {
            this.addNewRowOfClouds(100 * i);
        }
    }
    addNewRowOfClouds(x = 1300) {
        let position = Math.floor(Math.random() * 3) + 1;
        this.clouds.add(new cloud_1.Cloud({
            scene: this,
            x: x,
            y: position * 40,
            texture: `cloud-${position}`,
        }));
    }
}
exports.SkyScene = SkyScene;
