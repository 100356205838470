"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const base_registry_1 = require("./base-registry");
class ScoreRegistry extends base_registry_1.IBaseRegistry {
    constructor() {
        super('score');
    }
    setScore(val) {
        this.setVal(val);
    }
    getScore() {
        return this.getVal();
    }
    resetScore() {
        this.resetVal();
    }
}
exports.default = ScoreRegistry;
