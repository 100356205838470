"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Ground = void 0;
const constants_1 = require("../constants");
class Ground extends Phaser.GameObjects.Image {
    constructor(aParams, isSetVelocity = true) {
        super(aParams.scene, aParams.x, aParams.y, aParams.texture);
        // image
        this.setOrigin(0, 0);
        this.setDepth(5);
        // physics
        this.scene.physics.world.enable(this);
        this.body.allowGravity = false;
        if (isSetVelocity) {
            this.body.setVelocityX(-constants_1.CONST.VELOCITY * constants_1.CONST.SPEED);
        }
        this.body.setImmovable(true);
        this.scene.add.existing(this);
    }
}
exports.Ground = Ground;
