"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameOverScene = void 0;
const constants_1 = require("../constants");
class GameOverScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'GameOver',
        });
        this.fallingDiv = () => {
            const congDiv = document.createElement('div');
            congDiv.className = 'sub-card falling';
            const title = document.createElement('h1');
            title.innerHTML = 'FALLING';
            congDiv.appendChild(title);
            return congDiv;
        };
    }
    preload() {
        this.load.audio('gameOver', ['assets/audio/game-over.mp3']);
        this.load.image('playAgainButton', 'assets/images/play-again.webp');
    }
    create() {
        this.sound.stopByKey('backgroundMusic');
        const gameOverAudio = this.sound.add('gameOver', { loop: false });
        gameOverAudio === null || gameOverAudio === void 0 ? void 0 : gameOverAudio.play();
        const { width, height } = this.sys.game.canvas;
        const backgroundImage = this.add.image(0, 0, 'landingBackground');
        backgroundImage.setOrigin(0, 0);
        backgroundImage.displayWidth = width;
        backgroundImage.displayHeight = height;
        this.createGameOverInfo();
        this.events.on('shutdown', () => {
            const form = document.getElementById('gameOverCard');
            if (form) {
                form.remove();
            }
        });
    }
    createGameOverInfo() {
        const overCard = document.createElement('div');
        overCard.id = 'gameOverCard';
        overCard.className = 'card';
        const title = document.createElement('h1');
        title.innerHTML = `Opps! <br/> That was a hard landing`;
        title.className = `game-over-title`;
        overCard.appendChild(title);
        // overCard.appendChild(this.fallingDiv());
        const imgGameOver = document.createElement('img');
        imgGameOver.src = 'assets/images/icons/game-over.webp';
        imgGameOver.className = 'game-over-icon';
        overCard.appendChild(imgGameOver);
        overCard.className = 'card game-over-falling';
        const nextButton = document.createElement('button');
        nextButton.id = 'nextButton';
        nextButton.className = 'gap_button';
        nextButton.innerHTML = 'NEXT';
        nextButton.addEventListener('mouseover', () => {
            nextButton.classList.add('gap-button-hover');
        });
        nextButton.addEventListener('mouseout', () => {
            nextButton.classList.remove('gap-button-hover');
        });
        nextButton.addEventListener('click', () => {
            this.scene.start(constants_1.SceneCons.CongratulationScene);
        });
        overCard.appendChild(nextButton);
        this.add.dom(0, 0).createFromHTML(overCard.outerHTML);
        document.body.appendChild(overCard);
    }
}
exports.GameOverScene = GameOverScene;
