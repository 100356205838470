"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActualRankByUserIdAsync = exports.getLeaderBoardAsync = exports.saveLeaderboardAsync = exports.getUserId = void 0;
const HTTPClient_1 = require("./HTTPClient");
const logger_1 = require("./logger");
const score_local_storage_1 = require("./score-local-storage");
const client = new HTTPClient_1.default('https://dev-game.gapdasher.com/api/');
const getUserId = (email, playerName) => __awaiter(void 0, void 0, void 0, function* () {
    const model = {
        name: playerName || undefined,
        email: email || undefined,
        distance: 1,
        battery: 1,
        league_level: 'Beginner',
    };
    (0, logger_1.firebaseLogEvent)('getUserId', model);
    const result = yield client.post('leagues/leader-board', model);
    return result;
});
exports.getUserId = getUserId;
const saveLeaderboardAsync = (distance, batteryAmount) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const scoreLocalStorage = new score_local_storage_1.default();
        const email = scoreLocalStorage.getPlayerEmail();
        const playerName = scoreLocalStorage.getPlayerName();
        const model = {
            name: playerName || undefined,
            email: email || undefined,
            distance,
            league_level: scoreLocalStorage.getRank(),
            battery: batteryAmount,
        };
        (0, logger_1.postScore)(distance, playerName || '', email || '');
        const result = yield client.post('leagues/leader-board', model);
        return result;
    }
    catch (ex) {
        (0, logger_1.firebaseLogEvent)('saveLeaderboardAsync-error', { error: ex });
    }
});
exports.saveLeaderboardAsync = saveLeaderboardAsync;
const getLeaderBoardAsync = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield client.get(`leagues/leader-board`);
        (0, logger_1.firebaseLogEvent)('getLeaderBoardAsync', result);
        return result;
    }
    catch (ex) {
        (0, logger_1.firebaseLogEvent)('getLeaderBoardAsync-error', { error: ex });
    }
});
exports.getLeaderBoardAsync = getLeaderBoardAsync;
const getActualRankByUserIdAsync = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        (0, logger_1.firebaseLogEvent)('getActualRankByUserIdAsync', { userId });
        const result = yield client.get(`leagues/leader-board?user_id=${userId}`);
        return result;
    }
    catch (ex) {
        (0, logger_1.firebaseLogEvent)('getActualRankByUserIdAsync-error', { error: ex });
    }
});
exports.getActualRankByUserIdAsync = getActualRankByUserIdAsync;
