"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vehicle = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const http_helper_1 = require("../utils/http-helper");
const registrys_1 = require("../utils/registrys");
class Vehicle extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y) {
        super(scene, x, y, 'car-run');
        this.textureKey = 'car-run';
        this.jumpTextureKey = 'car-jump';
        this.dieTextureKey = 'car-die';
        this.lowBatteryTextureKey = 'car-low-battery';
        this.currentSpeed = 0;
        this._scoreRegistry = new registrys_1.ScoreRegistry();
        this.startTime = new Date();
        scene.add.existing(this);
        scene.physics.add.existing(this);
        const currentSpeed = (0, utils_1.getSpeed)();
        this.currentSpeed = currentSpeed;
        this.setVelocityX(currentSpeed);
        this.setSize(25, 49);
        this.setScale(2);
        this.setGravityY(500);
        // variables
        this.clickStartTime = 0;
        this.clickHoldDuration = 0;
        this.clickHoldThreshold = 100; // 0.1s
        this.velocityY = 0;
        // Create animations
        scene.anims.create({
            key: 'turn',
            frames: scene.anims.generateFrameNumbers(this.textureKey, {
                start: 0,
                end: 3,
            }),
            frameRate: 8,
            repeat: -1,
        });
        scene.anims.create({
            key: 'up',
            frames: scene.anims.generateFrameNumbers(this.jumpTextureKey, {
                start: 2,
                end: 2,
            }),
            frameRate: 1,
            repeat: -1,
        });
        scene.anims.create({
            key: 'down',
            frames: scene.anims.generateFrameNumbers(this.jumpTextureKey, {
                start: 3,
                end: 3,
            }),
            frameRate: 1,
            repeat: -1,
        });
        scene.anims.create({
            key: 'die',
            frames: scene.anims.generateFrameNumbers(this.dieTextureKey, {
                start: 0,
                end: 5,
            }),
            frameRate: 10,
            repeat: -1,
        });
        scene.anims.create({
            key: 'lowBattery',
            frames: scene.anims.generateFrameNumbers(this.lowBatteryTextureKey, {
                start: 0,
                end: 5,
            }),
            frameRate: 4,
            repeat: -1,
        });
        this.scene.input.on('pointerdown', () => {
            this.onKeyDownEvent();
        }, this);
        this.scene.input.on('pointerup', () => {
            this.onKeyUpEvent();
        }, this);
        this.scene.input.keyboard.on('keydown-SPACE', () => {
            this.onKeyDownEvent();
        }, this);
        this.scene.input.keyboard.on('keyup-SPACE', () => {
            this.onKeyUpEvent();
        }, this);
        this.scene.time.addEvent({
            delay: 1000,
            callback: this.calculatorDistance,
            callbackScope: this,
            loop: true,
        });
    }
    update(batteryPercent) {
        if (this.body.touching.down) {
            this.setVelocityX(this.currentSpeed);
            if (batteryPercent > constants_1.CONST.MAX_BATTERY / 3) {
                this.anims.play('turn', true);
            }
            else {
                this.anims.play('lowBattery', true);
            }
        }
        else {
            this.setVelocityX(0);
        }
        if (this.jumping) {
            const currentTime = new Date().getTime();
            this.clickHoldDuration = currentTime - this.clickStartTime;
            if (this.clickHoldDuration < this.clickHoldThreshold * 5) {
                this.setVelocityY(this.velocityY);
                this.anims.play('up', true);
            }
            else {
                this.jumping = false;
                this.anims.play('down', true);
            }
        }
        // TODO
        // check if off the screen
        if (this.y + this.height + 180 > this.scene.sys.canvas.height) {
            this.anims.play('die', true);
        }
        if (this.y + this.height > this.scene.sys.canvas.height) {
            this.gameOver();
        }
        if (batteryPercent <= 0) {
            this.gameOver();
        }
    }
    onKeyDownEvent() {
        this.clickStartTime = new Date().getTime();
        this.clickHoldDuration = 0;
        this.jumping = this.body.touching.down;
        this.velocityY = -160;
    }
    onKeyUpEvent() {
        this.jumping = false;
    }
    stopObject() {
        this.scene.scene.stop('Sky');
        this.scene.scene.stop('Distance');
        this.scene.scene.stop('DesertBack');
        this.scene.scene.stop('Desert');
        this.scene.scene.stop('RedRockScene');
        this.scene.scene.stop('SnackKangarooTreeScene');
        this.scene.scene.stop('Battery');
        this.scene.scene.stop('Level');
        this.scene.scene.stop('PinScene');
        this.scene.scene.stop('Mana');
        this.scene.scene.stop('DesertFront');
    }
    gameOver() {
        this.stopObject();
        this.scene.registry.set('numberOfTimesThePlayerSwitchesCutScene', 0);
        (0, http_helper_1.saveLeaderboardAsync)(this._scoreRegistry.getScore(), this.scene.registry.values.batteryConsumptionCount || 0);
        (0, http_helper_1.getActualRankByUserIdAsync)(this.scene.registry.values.userId).then(re => {
            const distance = re.length > 0 ? re[0].distance : 0;
            this.scene.registry.set('userActualDistance', distance);
        });
        this.scene.scene.switch('GameOver');
    }
    calculatorDistance() {
        const speed = this.currentSpeed;
        const time = (new Date().getTime() - this.startTime.getTime()) / 1000;
        const traveledDistance = speed * time;
        const distance = Math.round(traveledDistance / constants_1.CONST.CAR_LENGTH);
        const preRoundScore = this.scene.registry.get('preRoundScore') || 0;
        this._scoreRegistry.setScore(preRoundScore + distance);
    }
}
exports.Vehicle = Vehicle;
