"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AudioCons = {
    backgroundMusic: 'backgroundMusic',
    gameOver: 'gameOver',
    eatBatteryMusic: 'eatBatteryMusic',
};
class AudioManager {
    constructor(scene) {
        this.sounds = {};
        this.scene = scene;
    }
    playSound(key, config) {
        if (this.sounds[key]) {
            this.sounds[key].play(config);
        }
    }
    stopSound(key) {
        if (this.sounds[key]) {
            this.sounds[key].stop();
        }
    }
    pauseSound(key) {
        if (this.sounds[key]) {
            this.sounds[key].pause();
        }
    }
    resumeSound(key) {
        if (this.sounds[key]) {
            this.sounds[key].resume();
        }
    }
    loadSounds() {
        this.scene.load.audio(AudioCons.backgroundMusic, [
            'assets/audio/tunetank.mp3',
        ]);
        this.scene.load.audio(AudioCons.gameOver, ['assets/audio/game-over.mp3']);
        this.scene.load.audio(AudioCons.eatBatteryMusic, [
            'assets/audio/eat-battery.mp3',
        ]);
    }
    initialAddSound() {
        this.sounds[AudioCons.backgroundMusic] = this.scene.sound.add(AudioCons.backgroundMusic, { loop: true });
        this.sounds[AudioCons.gameOver] = this.scene.sound.add(AudioCons.gameOver, {
            loop: false,
        });
        this.sounds[AudioCons.eatBatteryMusic] = this.scene.sound.add(AudioCons.eatBatteryMusic, { loop: false });
    }
    playBackgroundMusic() {
        this.playSound(AudioCons.backgroundMusic, { loop: true });
    }
    stopBackgroundMusic() {
        this.stopSound(AudioCons.backgroundMusic);
    }
    pauseBackgroundMusic() {
        this.pauseSound(AudioCons.backgroundMusic);
    }
    //game over sound
    playGameOverSound() {
        this.playSound(AudioCons.gameOver);
    }
    stopGameOverSound() {
        this.stopSound(AudioCons.gameOver);
    }
    pauseGameOverSound() {
        this.pauseSound(AudioCons.gameOver);
    }
    //eat battery sound
    playEatBatterySound() {
        this.playSound(AudioCons.eatBatteryMusic);
    }
    stopEatBatterySound() {
        this.stopSound(AudioCons.eatBatteryMusic);
    }
    pauseEatBatterySound() {
        this.pauseSound(AudioCons.eatBatteryMusic);
    }
}
exports.default = AudioManager;
