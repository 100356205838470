"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Battery = void 0;
const constants_1 = require("../constants");
class Battery extends Phaser.Physics.Arcade.Sprite {
    constructor(scene) {
        super(scene, 60, 30, 'battery', 0);
        this.setScale(2);
        scene.add.existing(this);
        this.distanceText = this.scene.add.bitmapText(120, 27, 'font', '', 12);
    }
    update(batteryPercent) {
        this.distanceText.setText(`${batteryPercent}%`);
        if (batteryPercent > (constants_1.CONST.MAX_BATTERY * 2) / 3) {
            // More than 2/3 energy, show full battery
            this.setFrame(0);
        }
        else if (batteryPercent > constants_1.CONST.MAX_BATTERY / 3) {
            // Between 1/3 and 2/3, show medium battery
            this.setFrame(1);
        }
        else {
            // Less than 1/3, show low battery
            this.setFrame(2);
        }
    }
}
exports.Battery = Battery;
