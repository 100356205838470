"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameScene = void 0;
const sky_1 = require("../objects/sky");
const main_1 = require("../objects/main");
const desert_front_1 = require("../objects/desert-front");
const desert_back_1 = require("../objects/desert-back");
const red_rock_1 = require("../objects/red-rock");
const snack_kangaroo_tree_1 = require("../objects/snack-kangaroo-tree");
const level_1 = require("../objects/level");
const mana_1 = require("../objects/mana");
const desert_1 = require("../objects/desert");
const registrys_1 = require("../utils/registrys");
class GameScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'GameScene',
        });
        this._scoreRegistry = new registrys_1.ScoreRegistry();
    }
    init() {
        const preRoundScore = this.registry.get('preRoundScore');
        const batteryConsumptionCount = this.registry.get('batteryConsumptionCount');
        const times = Number(this.registry.get('numberOfTimesThePlayerSwitchesCutScene') || 0);
        this._scoreRegistry.setScore(preRoundScore || 0);
        this.registry.set('batteryConsumptionCount', batteryConsumptionCount || 0);
        this.registry.set('numberOfTimesThePlayerSwitchesCutScene', times);
    }
    create() {
        // Initial Sky
        try {
            this.scene.add('Sky', sky_1.SkyScene, true);
        }
        catch (error) {
            // Mean that already add SkyScene
            this.scene.start('Sky');
        }
        // Initial Desert Back
        try {
            this.scene.add('DesertBack', desert_back_1.DesertBackScene, true);
        }
        catch (error) {
            this.scene.start('DesertBack');
        }
        // Initial Desert
        try {
            this.scene.add('DesertFront', desert_front_1.DesertFrontScene, true);
        }
        catch (error) {
            this.scene.start('DesertFront');
        }
        // Initial Red Rock
        try {
            this.scene.add('RedRockScene', red_rock_1.RedRockScene, true);
        }
        catch (error) {
            this.scene.start('RedRockScene');
        }
        // Initial Desert Back
        try {
            this.scene.add('Desert', desert_1.DesertScene, true);
        }
        catch (error) {
            this.scene.start('Desert');
        }
        // Initial Snack Kangaroo Tree
        try {
            this.scene.add('SnackKangarooTreeScene', snack_kangaroo_tree_1.SnackKangarooTreeScene, true);
        }
        catch (error) {
            this.scene.start('SnackKangarooTreeScene');
        }
        // // Initial Mana
        try {
            this.scene.add('Mana', mana_1.ManaScene, true);
        }
        catch (error) {
            this.scene.start('Mana');
        }
        // Initial Level
        try {
            this.scene.add('Level', level_1.Level, true);
        }
        catch (error) {
            this.scene.start('Level');
        }
        // Initial Main game. In there objects will communicate with each other
        // almost logic actions will be handle in here
        try {
            this.scene.add('Main', main_1.MainScene, true);
        }
        catch (error) {
            this.scene.start('Main');
        }
    }
    update() { }
}
exports.GameScene = GameScene;
