"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesertBox = exports.DesertScene = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
class DesertScene extends Phaser.Scene {
    constructor() {
        super({ key: 'Desert' });
    }
    init(data) {
        this._isLoop = data.isLoop !== undefined ? data.isLoop : true;
    }
    create() {
        this.desert = this.add.group({});
        this.createInitialDesertFonts();
    }
    update() {
        this.desert.setDepth(100);
    }
    createInitialDesertFonts() {
        const minDistance = 120;
        for (let i = 0; i < 1000; i++) {
            const from = i * 2 * constants_1.CONST.WORLD_FRAME.W + minDistance;
            const to = (i + 1) * 2 * constants_1.CONST.WORLD_FRAME.W;
            const xPositions = (0, utils_1.getRandomXPositions)(from, to, 2, minDistance);
            xPositions.forEach(x => {
                this.addNewRowOfDesertFonts(x);
            });
        }
    }
    addNewRowOfDesertFonts(x) {
        const { height } = this.sys.canvas;
        const y = height - 330;
        this.desert.add(new DesertBox({
            scene: this,
            x: x,
            y: y,
            texture: `desert-font-2`,
        }, this._isLoop));
    }
}
exports.DesertScene = DesertScene;
class DesertBox extends Phaser.GameObjects.Image {
    constructor(aParams, isVelocity = true) {
        super(aParams.scene, aParams.x, aParams.y, aParams.texture);
        // image
        this.setOrigin(0, 0);
        this.setDepth(100);
        // physics
        this.scene.physics.world.enable(this);
        this.body.allowGravity = false;
        // It will move to the left 15% of the ground speed.
        if (isVelocity) {
            this.body.setVelocityX(-(constants_1.CONST.VELOCITY * 0.15 * constants_1.CONST.SPEED));
        }
        this.scene.add.existing(this);
    }
}
exports.DesertBox = DesertBox;
