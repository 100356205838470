"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CutScene = void 0;
const ground_1 = require("../ground");
const vehicle_1 = require("../cut-scenes/vehicle");
const plane_1 = require("./plane");
class CutScene extends Phaser.Scene {
    constructor() {
        super({ key: 'MainCutScene' });
    }
    create() {
        this._audioManager = this.game.audioManager;
        this._audioManager.playBackgroundMusic();
        this.ground = this.add.group({});
        this.createInitialGround();
        this.vehicle = new vehicle_1.Vehicle(this, -50, this.sys.canvas.height / 2 - 50);
        this.plane = new plane_1.Plane(this, 50, 50);
        this.physics.add.collider(this.vehicle, this.ground, this.vehicleHitGround, null, this);
        this.physics.add.collider(this.plane, this.ground, this.planeHitGround, null, this);
        this.physics.add.collider(this.vehicle, this.plane, this.vehicleHitPlane, null, this);
    }
    update() {
        const width = Number(this.sys.game.config.width);
        const planeX = this.plane.x + this.plane.width / 2 - 50;
        if (planeX >= width) {
            this.switchMainScene();
        }
        this.vehicle.update();
    }
    createInitialGround() {
        for (let i = -5; i < 15; i++) {
            this.addNewRowOfGround(100 * i);
        }
    }
    addNewRowOfGround(x = 1300) {
        this.ground.add(new ground_1.Ground({
            scene: this,
            x: x,
            y: this.sys.canvas.height - 200,
            texture: `ground`,
        }, false));
    }
    vehicleHitGround() { }
    planeHitGround() {
        this.plane.setVelocityY(0);
        this.plane.setVelocityX(0);
        this.plane.startPlaneLands();
        setTimeout(() => {
            this.vehicle.vehicleStart();
        }, 1000);
    }
    vehicleHitPlane() {
        this.vehicle.setVelocityX(0);
        this.vehicle.setAccelerationY(0);
        this.vehicle.setActive(false).setVisible(false);
        this.plane.planeTakeOff();
    }
    switchMainScene() {
        this.scene.stop('Sky');
        this.scene.stop('DesertBack');
        this.scene.stop('RedRockScene');
        this.scene.stop('Desert');
        this._audioManager.stopBackgroundMusic();
        this.scene.switch('GameScene');
    }
}
exports.CutScene = CutScene;
