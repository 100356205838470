"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LandingPageDisableScene = void 0;
const constants_1 = require("../constants");
class LandingPageDisableScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'LandingPageDisableScene',
        });
    }
    create() {
        this.header();
        this.addBackgroundImage();
        this.createUserForm();
        this.events.on('shutdown', () => {
            const gap_dash_form = document.getElementById('landing-page-form');
            if (gap_dash_form)
                gap_dash_form.remove();
            const bg = document.getElementById('landing-bg');
            if (bg)
                bg.remove();
        });
    }
    addBackgroundImage() {
        const divWrapper = document.createElement('div');
        divWrapper.id = 'landing-bg';
        const divInner = document.createElement('div');
        divInner.className = 'landing-bg-inner';
        const divContent = document.createElement('div');
        divContent.className = 'landing-bg-content';
        const imgDes = document.createElement('img');
        imgDes.src = './assets/images/des-mb.webp ';
        imgDes.className = 'des-mb';
        const imgHome = document.createElement('img');
        imgHome.src = './assets/images/home-mb-button.webp ';
        imgHome.className = 'home-mb-button';
        divContent.appendChild(imgDes);
        divContent.appendChild(imgHome);
        divInner.appendChild(divContent);
        divWrapper.appendChild(divInner);
        this.add.dom(0, 0).createFromHTML(divWrapper.outerHTML);
        document.body.appendChild(divWrapper);
        imgHome.addEventListener('click', () => {
            this.scene.start(constants_1.SceneCons.LandingPageScene);
        });
    }
    createUserForm() {
        let gapDashForm = document.createElement('div');
        gapDashForm.id = 'landing-page-form';
        gapDashForm.style.position = 'absolute';
        this.chPlayButton = document.createElement('button');
        this.chPlayButton.type = 'button';
        this.chPlayButton.id = 'ch_play_button';
        this.chPlayButton.className = 'landing-page-button';
        this.chPlayButton.innerHTML = '';
        this.appStoreButton = document.createElement('button');
        this.appStoreButton.type = 'button';
        this.appStoreButton.id = 'app_store_button';
        this.appStoreButton.className = 'landing-page-button';
        this.appStoreButton.innerHTML = '';
        let divStoreWrapper = document.createElement('div');
        divStoreWrapper.className = 'store-wrapper';
        divStoreWrapper.appendChild(this.chPlayButton);
        divStoreWrapper.appendChild(this.appStoreButton);
        let comingSoon = document.createElement('img');
        comingSoon.src = './assets/images/stores/app-comingsoon.webp';
        comingSoon.className = 'coming-soon-title';
        gapDashForm.appendChild(comingSoon);
        gapDashForm.appendChild(divStoreWrapper);
        this.add.dom(0, 0).createFromHTML(gapDashForm.outerHTML);
        document.body.appendChild(gapDashForm);
        this.chPlayButton.addEventListener('click', () => {
            // alert('App is coming soon');
        });
        this.appStoreButton.addEventListener('click', () => {
            // alert('App is coming soon');
        });
    }
    header() {
        const header = document.createElement('header');
        header.id = 'landing-page-header';
        header.className = 'header-opacity';
        const logo = document.createElement('img');
        logo.src = './assets/images/stores/logo.webp';
        logo.className = 'landing-page-logo';
        header.appendChild(logo);
        this.add.dom(0, 0).createFromHTML(header.outerHTML);
        document.body.appendChild(header);
    }
}
exports.LandingPageDisableScene = LandingPageDisableScene;
