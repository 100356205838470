"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.writeRegistry = exports.readRegistry = exports.getGameInstance = exports.setGameInstance = void 0;
const logger_1 = require("../logger");
let gameInstance = null;
const setGameInstance = (game) => {
    try {
        gameInstance = game;
    }
    catch (ex) {
        console.log('ex', ex);
        (0, logger_1.firebaseLogEvent)('setGameInstance-error', { error: ex });
    }
};
exports.setGameInstance = setGameInstance;
const getGameInstance = () => {
    try {
        return gameInstance;
    }
    catch (ex) {
        console.log('ex', ex);
        (0, logger_1.firebaseLogEvent)('getGameInstance-error', { error: ex });
        return null;
    }
};
exports.getGameInstance = getGameInstance;
const readRegistry = (key) => {
    const game = (0, exports.getGameInstance)();
    if (game) {
        const value = game.registry.get(key);
        return value;
    }
    else {
        console.warn('Game instance is not set.');
        return null;
    }
};
exports.readRegistry = readRegistry;
const writeRegistry = (key, value) => {
    const game = (0, exports.getGameInstance)();
    if (game) {
        game.registry.set(key, value);
    }
    else {
        console.warn('Game instance is not set.');
    }
};
exports.writeRegistry = writeRegistry;
class GameRegistry {
    static setTimesThePlayerSwitchesCutScene(val) {
        if (!val) {
            const times = (0, exports.readRegistry)(this.TIMES_USER_SWITCHES_CUT_SCENE) || 0;
            (0, exports.writeRegistry)(this.TIMES_USER_SWITCHES_CUT_SCENE, times + 1);
        }
        else {
            (0, exports.writeRegistry)(this.TIMES_USER_SWITCHES_CUT_SCENE, val);
        }
    }
    static getTimesThePlayerSwitchesCutScene() {
        return (0, exports.readRegistry)(this.TIMES_USER_SWITCHES_CUT_SCENE);
    }
    static resetTimesThePlayerSwitchesCutScene() {
        (0, exports.writeRegistry)(this.TIMES_USER_SWITCHES_CUT_SCENE, 0);
    }
}
exports.default = GameRegistry;
GameRegistry.TIMES_USER_SWITCHES_CUT_SCENE = 'numberOfTimesThePlayerSwitchesCutScene';
