"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SceneCons = exports.CONST = void 0;
exports.CONST = {
    BACKGROUND_COLOR: '#30b1fa',
    WORLD_FRAME: {
        W: 1200,
        H: 600,
    },
    VELOCITY: 300,
    MAX_BATTERY: 100,
    SPEED: Math.min(Math.max(Number(new URLSearchParams(window.location.search).get('speed')) || 1.2, 1), 5),
    PATH_LENGTH: Math.min(Number(new URLSearchParams(window.location.search).get('distance') || 9000), 9000),
    SPEED_PERCENT_INCREASE: 25,
    CAR_LENGTH: 2, //in meters
};
exports.SceneCons = {
    CongratulationScene: 'CongratulationScene',
    LeaderBoardScene: 'LeaderBoardScene',
    MainMenuScene: 'MainMenuScene',
    CutScene: 'CutScene',
    GameOver: 'GameOver',
    BootScene: 'BootScene',
    GameScene: 'GameScene',
    LandingPageScene: 'LandingPageScene',
    LandingPageDisableScene: 'LandingPageDisableScene',
};
