"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BootScene = void 0;
const constants_1 = require("../constants");
class BootScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'BootScene',
        });
    }
    create() { }
    preload() {
        // set the background and create loading bar
        this.cameras.main.setBackgroundColor(constants_1.CONST.BACKGROUND_COLOR);
        this.createLoadingBar();
        // pass value to change the loading bar fill
        this.load.on('progress', function (value) {
            this.progressBar.clear();
            this.progressBar.fillStyle(0xfff6d3, 1);
            this.progressBar.fillRect(this.cameras.main.width / 4, this.cameras.main.height / 2 - 16, (this.cameras.main.width / 2) * value, 16);
        }, this);
        // delete bar graphics, when loading complete
        this.load.on('complete', function () {
            this.progressBar.destroy();
            this.loadingBar.destroy();
        }, this);
        // load out package
        this.load.pack('preload', './assets/pack.json', 'preload');
    }
    update() {
        this.scene.start(constants_1.SceneCons.LandingPageScene);
    }
    createLoadingBar() {
        this.loadingBar = this.add.graphics();
        this.loadingBar.fillStyle(0x5dae47, 1);
        this.loadingBar.fillRect(this.cameras.main.width / 4 - 2, this.cameras.main.height / 2 - 18, this.cameras.main.width / 2 + 4, 20);
        this.progressBar = this.add.graphics();
    }
}
exports.BootScene = BootScene;
