"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CutScene = void 0;
const sky_1 = require("../objects/sky");
const desert_1 = require("../objects/desert");
const desert_back_1 = require("../objects/desert-back");
const red_rock_1 = require("../objects/red-rock");
const main_1 = require("../objects/cut-scenes/main");
const desert_front_1 = require("../objects/desert-front");
const logger_1 = require("../utils/logger");
const score_local_storage_1 = require("../utils/score-local-storage");
class CutScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'CutScene',
        });
    }
    init() {
        const currentTimes = this.registry.get('numberOfTimesThePlayerSwitchesCutScene');
        this.registry.set('numberOfTimesThePlayerSwitchesCutScene', currentTimes + 1);
        const scoreLocalStorage = new score_local_storage_1.default();
        const email = scoreLocalStorage.getPlayerEmail();
        const playerName = scoreLocalStorage.getPlayerName();
        (0, logger_1.logLevelCompletion)(currentTimes + 1, playerName, email);
        this.registry.set('preRoundScore', this.registry.get('score'));
    }
    create() {
        // Initial Sky
        try {
            this.scene.add('Sky', sky_1.SkyScene, true);
        }
        catch (error) {
            // Mean that already add SkyScene
            this.scene.start('Sky');
        }
        // Initial Desert
        try {
            this.scene.add('DesertFront', desert_front_1.DesertFrontScene, true);
        }
        catch (error) {
            this.scene.start('DesertFront');
        }
        // Initial Desert Back
        try {
            this.scene.add('DesertBack', desert_back_1.DesertBackScene, true, { isLoop: false });
        }
        catch (error) {
            this.scene.start('DesertBack');
        }
        // Initial Red Rock
        try {
            this.scene.add('RedRockScene', red_rock_1.RedRockScene, true, { isLoop: false });
        }
        catch (error) {
            this.scene.start('RedRockScene');
        }
        // Initial Desert
        try {
            this.scene.add('Desert', desert_1.DesertScene, true, { isLoop: false });
        }
        catch (error) {
            this.scene.start('Desert');
        }
        // Initial Main game. In there objects will communicate with each other
        // almost logic actions will be handle in here
        try {
            this.scene.add('MainCutScene', main_1.CutScene, true);
        }
        catch (error) {
            this.scene.start('MainCutScene');
        }
    }
    update() { }
}
exports.CutScene = CutScene;
