"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoxObject = exports.SnackKangarooTreeScene = void 0;
const constants_1 = require("../constants");
class SnackKangarooTreeScene extends Phaser.Scene {
    constructor() {
        super({ key: 'SnackKangarooTreeScene' });
    }
    create() {
        this.groups = this.add.group({});
        this.initScene();
        this.time.addEvent({
            delay: 4000,
            callback: this.addNew,
            callbackScope: this,
            loop: true,
        });
    }
    update() { }
    initScene() {
        for (let i = 0; i <= 8; i++) {
            this.addNew(300 * i);
        }
    }
    addNew(x = 1300) {
        const rndInt = Math.floor(Math.random() * 3) + 1;
        const gap = Math.floor(Math.random() * 150) + 100;
        switch (rndInt) {
            case 1:
                this.groups.add(new BoxObject({
                    scene: this,
                    x,
                    y: this.sys.canvas.height - gap,
                    texture: 'tree',
                }));
                break;
            case 2:
                this.groups.add(new BoxObject({
                    scene: this,
                    x,
                    y: this.sys.canvas.height - gap,
                    texture: 'kangaroo',
                }));
                break;
            default:
                this.groups.add(new BoxObject({
                    scene: this,
                    x,
                    y: this.sys.canvas.height - gap,
                    texture: 'snack',
                }));
                break;
        }
    }
}
exports.SnackKangarooTreeScene = SnackKangarooTreeScene;
class BoxObject extends Phaser.GameObjects.Image {
    constructor(aParams) {
        super(aParams.scene, aParams.x, aParams.y, aParams.texture);
        // image
        this.setScale(aParams.texture === 'snack' ? 1.5 : 2.5);
        this.setOrigin(0, 0);
        // physics
        this.scene.physics.world.enable(this);
        this.body.allowGravity = false;
        // It will move to the left 15% of the ground speed.
        this.body.setVelocityX(-(constants_1.CONST.VELOCITY * 0.15 * constants_1.CONST.SPEED));
        this.scene.add.existing(this);
    }
}
exports.BoxObject = BoxObject;
