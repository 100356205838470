"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameConfig = void 0;
const constants_1 = require("./constants");
const boot_scene_1 = require("./scenes/boot-scene");
const cut_scene_1 = require("./scenes/cut-scene");
const game_scene_1 = require("./scenes/game-scene");
const main_menu_scene_1 = require("./scenes/main-menu-scene");
const leaderboard_scene_1 = require("./scenes/leaderboard-scene");
const landing_page_scene_1 = require("./scenes/landing-page-scene");
const landing_page_disable_scene_1 = require("./scenes/landing-page-disable-scene");
exports.GameConfig = {
    title: 'Gap dash',
    url: 'https://sourcecode.com.au',
    version: '0.0.1',
    width: constants_1.CONST.WORLD_FRAME.W,
    height: constants_1.CONST.WORLD_FRAME.H,
    type: Phaser.AUTO,
    parent: 'game',
    scene: [
        boot_scene_1.BootScene,
        main_menu_scene_1.MainMenuScene,
        game_scene_1.GameScene,
        cut_scene_1.CutScene,
        leaderboard_scene_1.LeaderBoardScene,
        landing_page_scene_1.LandingPageScene,
        landing_page_disable_scene_1.LandingPageDisableScene,
    ],
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    input: {
        keyboard: true,
    },
    physics: {
        default: 'arcade',
        arcade: {
            gravity: {
                y: 300,
                x: 0,
            },
        },
    },
    backgroundColor: constants_1.CONST.BACKGROUND_COLOR,
    render: { pixelArt: true, antialias: false },
};
