"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cloud = void 0;
const constants_1 = require("../constants");
class Cloud extends Phaser.GameObjects.Image {
    constructor(aParams) {
        super(aParams.scene, aParams.x, aParams.y, aParams.texture);
        // image
        this.setScale(2);
        this.setOrigin(0, 0);
        // physics
        this.scene.physics.world.enable(this);
        this.body.allowGravity = false;
        //  These are clouds and are top-most in the BACKGROUND layer group.
        // These will move to the left about 10% of the ground speed.
        this.body.setVelocityX(-(constants_1.CONST.VELOCITY * 0.1 * constants_1.CONST.SPEED));
        this.scene.add.existing(this);
    }
}
exports.Cloud = Cloud;
