"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManaScene = void 0;
class ManaScene extends Phaser.Scene {
    constructor() {
        super({ key: 'Mana' });
        this.maxEnergy = 100; // Assuming 30 is the maximum energy for full battery
        this.currentEnergy = this.maxEnergy; // Set the current energy to max
    }
    create() { }
    update() {
        // Update your game logic here
    }
    decreaseEnergy(amount) {
        this.currentEnergy = Math.max(this.currentEnergy - amount, 0);
        this.updateEnergyDisplay();
    }
    increaseEnergy(amount) {
        this.currentEnergy = Math.min(this.currentEnergy + amount, this.maxEnergy);
        this.updateEnergyDisplay();
    }
    updateEnergyDisplay() { }
}
exports.ManaScene = ManaScene;
