"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rock = exports.RedRockScene = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
class RedRockScene extends Phaser.Scene {
    constructor() {
        super({ key: 'RedRockScene' });
    }
    init(data) {
        this._isLoop = data.isLoop !== undefined ? data.isLoop : true;
    }
    create() {
        this.rocks = this.add.group({});
        this.createInitialRedRock();
        // if (this._isLoop) {
        //   this.time.addEvent({
        //     delay: 10000,
        //     callback: this.addNew,
        //     callbackScope: this,
        //     loop: true,
        //   });
        // }
    }
    update() { }
    createInitialRedRock() {
        const { height } = this.sys.canvas;
        const y = height - 330;
        const minDistance = 120;
        for (let i = 0; i < 1000; i++) {
            const from = i * 2 * constants_1.CONST.WORLD_FRAME.W + minDistance;
            const to = (i + 1) * 2 * constants_1.CONST.WORLD_FRAME.W;
            const xPositions = (0, utils_1.getRandomXPositions)(from, to, 1, minDistance);
            xPositions.forEach(x => {
                this.rocks.add(new Rock({ scene: this, x, y, texture: 'red-rock-1' }, this._isLoop));
            });
        }
    }
}
exports.RedRockScene = RedRockScene;
class Rock extends Phaser.GameObjects.Image {
    constructor(aParams, isVelocity = true) {
        super(aParams.scene, aParams.x, aParams.y, aParams.texture);
        // image
        this.setScale(1);
        this.setOrigin(0, 0);
        // physics
        this.scene.physics.world.enable(this);
        this.body.allowGravity = false;
        // It will move to the left 15% of the ground speed.
        if (isVelocity) {
            this.body.setVelocityX(-(constants_1.CONST.VELOCITY * 0.15 * constants_1.CONST.SPEED));
        }
        this.scene.add.existing(this);
    }
}
exports.Rock = Rock;
