"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaderBoardScene = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const http_helper_1 = require("../utils/http-helper");
class LeaderBoardScene extends Phaser.Scene {
    constructor() {
        super({
            key: 'LeaderBoardScene',
        });
    }
    create() {
        const { width, height } = this.sys.game.canvas;
        const backgroundImage = this.add.image(0, 0, 'landingBackground');
        backgroundImage.setOrigin(0, 0);
        backgroundImage.displayWidth = width;
        backgroundImage.displayHeight = height;
        this.sound.stopAll();
        this.boardInfo();
        this.events.on('shutdown', () => {
            const form = document.getElementById('leaderboard');
            if (form) {
                form.remove();
            }
        });
    }
    boardInfo() {
        const leaderboardWrapper = document.createElement('div');
        leaderboardWrapper.classList.add('leaderboard-wrapper');
        (0, http_helper_1.getLeaderBoardAsync)()
            .then(res => {
            res.forEach((el, index) => {
                const rank = index + 1;
                const row = document.createElement('div');
                row.classList.add('leaderboard-row');
                row.classList.add(rank > 3 ? 'leaderboard-row' : `leaderboard-row-top${rank}`);
                const divRank = document.createElement('div');
                divRank.className = `leaderboard-rank ${rank > 3 ? '' : 'top-' + rank}`;
                if (rank < 4) {
                    const img = document.createElement('img');
                    img.classList.add('leaderboard-rank-icon');
                    img.src = `assets/images/icons/${rank}.svg`;
                    divRank.appendChild(img);
                }
                else {
                    divRank.textContent = `${index + 1}`;
                }
                const divUser = document.createElement('div');
                divUser.textContent = el.full_name || 'unknown';
                divUser.classList.add('leaderboard-user');
                const divDistance = document.createElement('div');
                divDistance.textContent = `${(0, utils_1.convertToKm)(el.distance)}km`;
                divDistance.classList.add('leaderboard-distance');
                row.appendChild(divRank);
                row.appendChild(divUser);
                row.appendChild(divDistance);
                leaderboardWrapper.appendChild(row);
            });
        })
            .finally(() => {
            loadingWrapper.classList.remove('loader');
        });
        // Create leaderboard container
        const leaderboardDiv = document.createElement('div');
        leaderboardDiv.id = 'leaderboard';
        leaderboardDiv.classList.add('leaderboard-container');
        const loadingWrapper = document.createElement('div');
        loadingWrapper.classList.add('loader');
        //Create heading (h1) element
        const heading = document.createElement('h1');
        heading.textContent = 'Leaderboard';
        heading.className = 'game-over-title congratulation leaderboard';
        const headingWrapper = document.createElement('div');
        headingWrapper.classList.add('heading-wrapper');
        const adware = document.createElement('img');
        adware.src = 'assets/images/icons/award.webp';
        adware.className = 'adware-img';
        const adwareRight = document.createElement('img');
        adwareRight.src = 'assets/images/icons/award.webp';
        adwareRight.className = 'adware-img';
        headingWrapper.appendChild(adware);
        headingWrapper.appendChild(heading);
        headingWrapper.appendChild(adwareRight);
        leaderboardDiv.appendChild(headingWrapper);
        // Create table element
        const nextButton = document.createElement('button');
        nextButton.id = 'nextButton';
        nextButton.className = 'gap_button';
        nextButton.innerHTML = 'BACK';
        nextButton.addEventListener('mouseover', () => {
            nextButton.classList.add('gap-button-hover');
        });
        nextButton.addEventListener('mouseout', () => {
            nextButton.classList.remove('gap-button-hover');
        });
        nextButton.addEventListener('click', () => {
            this.scene.stop();
            this.scene.switch(constants_1.SceneCons.CongratulationScene);
        });
        leaderboardDiv.appendChild(leaderboardWrapper);
        leaderboardDiv.appendChild(loadingWrapper);
        leaderboardDiv.appendChild(nextButton);
        // Append the leaderboard container to the document body
        document.body.appendChild(leaderboardDiv);
    }
}
exports.LeaderBoardScene = LeaderBoardScene;
