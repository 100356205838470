"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainScene = void 0;
const constants_1 = require("../constants");
const utils_1 = require("../utils");
const game_registry_1 = require("../utils/registrys/game-registry");
const http_helper_1 = require("../utils/http-helper");
const battery_1 = require("./battery");
const ground_1 = require("./ground");
const ground_shadow_1 = require("./ground-shadow");
const pin_1 = require("./pin");
const vehicle_1 = require("./vehicle");
const registrys_1 = require("../utils/registrys");
class MainScene extends Phaser.Scene {
    constructor() {
        super({ key: 'Main' });
        this.SCORE_CUT_SCENE = constants_1.CONST.PATH_LENGTH;
        this._scoreRegistry = new registrys_1.ScoreRegistry();
    }
    create() {
        this._audioManager = this.game.audioManager;
        this._audioManager.playBackgroundMusic();
        const times = game_registry_1.default.getTimesThePlayerSwitchesCutScene() + 1;
        this.SCORE_CUT_SCENE = times * constants_1.CONST.PATH_LENGTH;
        this._speed = (0, utils_1.getSpeed)();
        this.ground = this.add.group({});
        this.shadows = this.add.group({});
        this.pin = this.add.group({});
        this.createInitialGround();
        this.batteryPercent = 100;
        this.holeCount = 0;
        this.flagAddingBattery = false;
        this.time.addEvent({
            delay: 10000,
            callback: this.addNewRowOfPin,
            callbackScope: this,
            loop: true,
        });
        this.time.addEvent({
            delay: (0, utils_1.calculateTimeAccordingToVelocity)(800),
            callback: () => {
                this.batteryPercent -= 1;
                this.batteryPercent = this.batteryPercent < 0 ? 0 : this.batteryPercent;
            },
            callbackScope: this,
            loop: true,
        });
        this.battery = new battery_1.Battery(this);
        const yPosition = this.sys.canvas.height / 2 - 50;
        this._vehicleYPosition = yPosition + 100;
        this.vehicle = new vehicle_1.Vehicle(this, 150, yPosition);
        this.physics.add.collider(this.vehicle, this.ground, this.vehicleHitGround, null, this);
        this.physics.add.collider(this.vehicle, this.pin, this.vehicleHitBattery, null, this);
    }
    update() {
        const score = this._scoreRegistry.getScore();
        const carIsJumping = Boolean(this.registry.get('jumping'));
        const numbers = Math.abs(this.vehicle.y - this._vehicleYPosition);
        if (this.SCORE_CUT_SCENE &&
            score > this.SCORE_CUT_SCENE &&
            !carIsJumping &&
            numbers < 5) {
            this.scene.stop('Sky');
            this.scene.stop('Distance');
            this.scene.stop('DesertBack');
            this.scene.stop('Desert');
            this.scene.stop('RedRockScene');
            this.scene.stop('SnackKangarooTreeScene');
            this.scene.stop('Battery');
            this.scene.stop('Level');
            this.scene.stop('PinScene');
            this.scene.stop('Mana');
            this.scene.stop('DesertFront');
            this.vehicle.stopObject();
            this.ground.clear(true, true);
            (0, http_helper_1.saveLeaderboardAsync)(this._scoreRegistry.getScore(), this.registry.values.batteryConsumptionCount || 0);
            //stop backgroundMusic
            this.sound.stopByKey('backgroundMusic');
            this.scene.switch(constants_1.SceneCons.CutScene);
        }
        this.vehicle.update(this.batteryPercent);
        this.battery.update(this.batteryPercent);
    }
    createInitialGround() {
        const startPoints = Array.from(Array(11).keys());
        // We need to get the player familiar with the terrain and acceleration before starting the game
        for (let i = 0; i < startPoints.length; i++) {
            this.addNewRowOfGround(100 * i);
        }
        const { level1, level2 } = (0, utils_1.generateLevels)(this.SCORE_CUT_SCENE);
        const times = Number(this.registry.get('numberOfTimesThePlayerSwitchesCutScene'));
        this.generateColumns(!times ? level1 : level2, startPoints);
    }
    generateColumns(level, startPoints) {
        level = level.map(value => value + startPoints.length);
        const endPoint = level[level.length - 1];
        for (let i = startPoints.length; i < endPoint + 1; i++) {
            if (level.includes(i)) {
                this.addNewRowOfGround(100 * i);
            }
        }
        for (let i = endPoint + 1; i < 200; i++) {
            const rndInt = Math.floor(Math.random() * 2) + 1;
            if (rndInt === 1 || this.holeCount >= 3) {
                this.addNewRowOfGround(100 * i);
                this.holeCount = 0;
            }
            else {
                this.holeCount++;
            }
        }
    }
    addNewRowOfGround(x = 1300) {
        const ground = new ground_1.Ground({
            scene: this,
            x: x,
            y: this.sys.canvas.height - 200,
            texture: `ground`,
        });
        const groundWidth = ground.width;
        const groundX = ground.x;
        const shadow = new ground_shadow_1.GroundShadow({
            scene: this,
            x: groundX - groundWidth + 5,
            y: ground.y + 30,
            texture: 'shadow',
        });
        shadow.setBlendMode(Phaser.BlendModes.MULTIPLY);
        ground.body.setVelocityX(-this._speed);
        shadow.body.setVelocityX(-this._speed);
        this.shadows.add(shadow);
        this.ground.add(ground);
    }
    addNewRowOfPin(x = 1300) {
        var yPosition = (0, utils_1.randomNumber)(470, 500);
        this.pin.add(new pin_1.Pin({
            scene: this,
            x,
            y: yPosition,
        }));
    }
    vehicleHitGround() {
        // handle when vehicle hit ground here
        // example: vehicle.setVelocityY(-200);
        // if (!this._vehicleYPosition) {
        //   this._vehicleYPosition = this.vehicle.y + 100;
        // }
        // if (this.registry.get('jumping')) {
        //   this.registry.set('jumping', false);
        // }
    }
    vehicleHitBattery(vehicle, pin) {
        pin.setActive(false).setVisible(false);
        pin.body.enable = false;
        pin.destroy();
        // TODO - Jason
        // this vehicleHitBattery called so many time
        // so I just find the way temporary to work here
        if (this.flagAddingBattery)
            return;
        this.registry.set('batteryConsumptionCount', this.registry.get('batteryConsumptionCount') + 1);
        this.flagAddingBattery = true;
        this.batteryPercent += 10;
        this.batteryPercent = this.batteryPercent > 100 ? 100 : this.batteryPercent;
        setTimeout(() => {
            this.flagAddingBattery = false;
        }, 1000);
        this._audioManager.playEatBatterySound();
    }
}
exports.MainScene = MainScene;
