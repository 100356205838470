"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Vehicle = void 0;
const constants_1 = require("../../constants");
class Vehicle extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y) {
        super(scene, x, y, 'car-run');
        this.textureKey = 'car-run';
        this.jumpTextureKey = 'car-jump';
        scene.add.existing(this);
        scene.physics.add.existing(this);
        this.setSize(25, 49);
        this.setScale(1.2);
        // variables
        // Create animations
        scene.anims.create({
            key: 'turn',
            frames: scene.anims.generateFrameNumbers(this.textureKey, {
                start: 0,
                end: 3,
            }),
            frameRate: 8,
            repeat: -1,
        });
    }
    vehicleStart() {
        this.anims.play('turn', true);
        this.setVelocityX(constants_1.CONST.VELOCITY * 0.3);
    }
    update() { }
}
exports.Vehicle = Vehicle;
